import Vue from 'vue';
import Vuetify from 'vuetify';
import colors from 'vuetify/es5/util/colors';
import nl from 'vuetify/es5/locale/nl';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#202124',
        error: colors.red.darken4,
      },
    },
  },
  lang: {
    locales: {
      nl,
    },
    current: 'nl',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
