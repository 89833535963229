import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

export default (routes) => {
  return new VueRouter({
    routes,
    mode: 'history',
  });
};
