import {
  mdiViewDashboard,
  mdiFolderMultipleImage,
  mdiFolderText,
  mdiMotorbike,
  mdiStar,
  mdiBellRing,
  mdiEmail,
} from '@mdi/js';

export default [{
  name: 'Dashboard',
  path: { name: 'dashboard' },
  icon: mdiViewDashboard,
  showInDashboard: false,
}, {
  name: 'Media',
  path: { name: 'media' },
  icon: mdiFolderMultipleImage,
  text: 'Upload foto\'s en filmpjes om te laten zien op de website.',
}, {
  name: 'Fotoalbums',
  path: { name: 'media-albums' },
  icon: mdiFolderText,
  text: 'Voeg nieuwe foto album\'s toe.',
}, {
  name: 'Motoren',
  path: { name: 'motor-brands.index' },
  icon: mdiMotorbike,
  text: 'Beheer de motoren die op de website staan.',
}, {
  name: 'Reviews',
  path: { name: 'reviews' },
  icon: mdiStar,
  text: 'Beheer de reviews die geplaatst zijn op de website. Keur ze goed, of verwijder ze.',
}, {
  name: 'Notificaties',
  path: { name: 'notifications' },
  icon: mdiBellRing,
  text: 'Beheer de notificaties die op de website zichtbaar zijn om klanten te informeren van een bepaalde situatie.',
}, {
  name: 'Email',
  path: '/admin/email',
  icon: mdiEmail,
  text: 'Beheer waar de contactformulieren naar toe worden gestuurd.',
},/* {
  name: 'Analytics',
  path: '/admin/analytics',
  icon: 'mdi-google-analytics',
  text: 'Krijg inzicht tot de bezoekers van de website.'
} */];
