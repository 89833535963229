const routes = [ {
  path: '/',
  redirect: '/dashboard',
}, {
  name: 'dashboard',
  path: '/dashboard',
  component: () => import('Pages/admin/Home'),
}, {
  path: '/contact',
  name: 'contact',
  component: () => import('Pages/admin/Contact'),
}, {
  path: '/media',
  name: 'media',
  component: () => import('Pages/admin/Media'),
}, {
  path: '/media-albums',
  name: 'media-albums',
  component: () => import('Pages/admin/MediaAlbums'),
}, {
  path: '/reviews',
  name: 'reviews',
  component: () => import('Pages/admin/Reviews'),
}, {
  path: '/email',
  name: 'email',
  component: () => import('Pages/admin/Email'),
}, {
  path: '/notificaties',
  name: 'notifications',
  component: () => import('Pages/admin/Notifications'),
}, {
  path: '/motor-brands',
  name: 'motor-brands.index',
  component: () => import('Pages/admin/Motors'),
}, {
  path: '/motor-brands/create',
  name: 'motor-brands.create',
  component: () => import('Pages/admin/CreateMotorBrand'),
}, {
  path: '/motor-brands/:id',
  name: 'motor-brands.show',
  component: () => import('Pages/admin/MotorsInBrand'),
  props: (route) => ({
    motorBrandId: parseInt(route.params.id),
  }),
}, {
  path: '/motors/:id',
  name: 'motors.show',
  component: () => import('Pages/admin/MotorShow'),
  props: (route) => ({
    motorId: parseInt(route.params.id),
  }),
}];

export default [...routes.map((value) => ({ ...value, path: '/admin' + value.path})),{
  name: 'login',
  path: '/login',
  component: () => import('Pages/admin/Login'),
}, {
  path: '*',
  component: () => import('Pages/errors/404'),
}];
