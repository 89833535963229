<template>
  <div id="app">
    <v-app>
      <template v-if="$authenticated">
        <Menu />
        <v-content>
          <v-container
            fluid
            class="pa-0"
          >
            <v-container>
              <v-alert
                v-model="state.errored"
                type="error"
                dismissible
              >
                Er is iets misgegaan. Probeer het nog eens.
              </v-alert>
            </v-container>
            <v-snackbar v-model="state.showInfo">
              {{ state.infoMessage }}
              <v-btn
                color="pink"
                text
                @click="state.showInfo = false"
              >
                Close
              </v-btn>
            </v-snackbar>
            <v-container>
              <router-view />
            </v-container>
          </v-container>
        </v-content>
      </template>
      <router-view v-else />
    </v-app>
  </div>
</template>
<script>
import store from './admin-store';
import Menu from './components/admin/Menu';

export default {
  components: {
    Menu,
  },
  data() {
    return {
      state: store.state,
    };
  },
};
</script>

<style lang="scss">
    body {
        margin: 0;
        padding: 0;
        font-family: 'Roboto', serif;
    }

    .container {
        max-width: 900px;
    }
</style>
