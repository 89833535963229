import Vue from 'vue';
import vuetify from './bootstrap/vuetify';
import routes from './router/routes/admin';
import App from './Admin';
import createRouter from './router'; // vue-router instance
import state from './admin-store';
import axios from 'axios';
window.axios = axios;

import './bootstrap';

Vue.prototype.$appName = process.env.APP_NAME;
Vue.prototype.$authenticated = window.authenticated;
Vue.prototype.$error = (error) => {
  state.errored = true;
  // eslint-disable-next-line no-console
  console.log(error);
};
Vue.prototype.$inform = (message) => {
  state.showInfo = true;
  state.infoMessage = message;
};

new Vue({
  render: h => h(App),
  router: createRouter(routes),
  vuetify,
}).$mount('#app');
