import Vue from 'vue';
import { init } from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

init({
  dsn: process.env.SENTRY_LARAVEL_DSN,
  environment: process.env.NODE_ENV,
  release: process.env.releashHash,
  debug: true,
  enabled: process.env.NODE_ENV === 'production',
  integrations: [new VueIntegration({ Vue })],
});
