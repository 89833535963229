<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      :temporary="$vuetify.breakpoint.smAndDown"
      app
    >
      <v-list class="pa-1">
        <v-list-item tag="div">
          <v-list-item-content>
            <v-list-item-title>
              Knalnaarpotz
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              icon
              @click="drawer = false"
            >
              <v-icon>{{ mdiChevronLeft }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-list
        class="pt-0"
        dense
        nav
        expand
      >
        <v-divider light />

        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      fixed
      dark
      scroll-off-screen
      :scroll-threshold="66"
      class="shadow"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = true"
      />
      <v-toolbar-title class="pr-3">
        {{ $appName }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn
            icon
            v-on="on"
            @click="logout()"
          >
            <v-icon>
              {{ mdiLogoutVariant }}
            </v-icon>
          </v-btn>
        </template>
        <span>Uitloggen</span>
      </v-tooltip>
    </v-app-bar>
  </div>
</template>

<script>
import axios from 'axios';
import menu from '../../data/menu';
import { mdiLogoutVariant, mdiChevronLeft } from '@mdi/js';

export default {
  data() {
    return {
      mdiLogoutVariant,
      mdiChevronLeft,
      activeIndex: '/home',
      menuItems: menu,
      drawer: !this.$vuetify.breakpoint.smAndDown,
    };
  },
  methods: {
    logout() {
      axios.post('/admin/logout')
        .then(() => {
          window.location.replace('/admin/login');
        })
        .catch(() => alert('Er is iets mis gegaan bij het uitloggen.'));
    },
  },
};
</script>

<style lang="scss" scoped>
  .shadow {
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px !important;
  }

  .top-black {
    top: 0;
    width: 100%;
    position: absolute;
  }

  .menu {
    z-index: 200;
    position: fixed;
    width: 100%;

    li {
      font-weight: 400 !important;
      font-size: 18px !important;
    }
  }
</style>
